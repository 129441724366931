function useSearchParam(history, name) {
  const searchParams = new URLSearchParams(history.location.search);

  const value = searchParams.get(name);

  const setValue = (newValue) => {
    searchParams.set(name, newValue);
    history.replace({ search: searchParams.toString() });
  };

  return [value, setValue];
}

export default useSearchParam;
