import React from 'react';

import Text from '../../common/Text/Text';
import Badge from '../../common/Badge/Badge';
import typeEnum from './typeEnum';
import ExternalLink from '../../common2/links/ExternalLink';
import { formatDate } from '../parcelFinderHelper';

function NotificationListItem(props) {
  const {
    notification: { time: strDate, type, eventType, subject, sendingLogicId },
  } = props;

  const sendingLogicLink = `/customer-notifications/sending-logic/${sendingLogicId}/edit`;
  const date = new Date(strDate);

  return (
    <tr>
      <td>
        <Text>{formatDate(date, 'MM-DD-YYYY HH:mm')}</Text>
      </td>
      <td>
        <Badge>{getNotificationDetail(type).type}</Badge>
      </td>
      <td>
        <Text>{eventType}</Text>
      </td>
      <td>
        <ExternalLink to={sendingLogicLink}>{subject}</ExternalLink>
      </td>
      <td>
        <Text>{getNotificationDetail(type).recipient}</Text>
      </td>
    </tr>
  );
}

function getNotificationDetail(type) {
  return typeEnum[type];
}

export default React.memo(NotificationListItem);
