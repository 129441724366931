import React from 'react';
import { useQuery } from '@tanstack/react-query';

import SettingsPage from '../../SettingsPage';
import IntercomMessengerButton from '../../common/IntercomMessengerButton';
import { useSelectedShopId } from '../../../hooks/useSelectedShop';
import container from '../../../container';
import Loader from '../../common/Loader';
import WarehousesList from './WarehousesList';
import LoadingError from '../../common/LoadingError';

function WarehousesPage(props) {
  const shopId = useSelectedShopId();

  const { isLoading, isError, data } = useQuery({
    queryKey: ['warehouses', shopId],
    queryFn: () => container.touchpointsApi.getWarehouses(shopId),
  });

  return (
    <SettingsPage
      {...props}
      title="Warehouses"
      renderHeaderElements={() => <IntercomMessengerButton>Add or change</IntercomMessengerButton>}
    >
      {isLoading && <Loader />}
      {isError && <LoadingError />}
      {data && <WarehousesList warehouses={data} />}
    </SettingsPage>
  );
}

export default WarehousesPage;
