import React from 'react';

import Text from '../Text';
import Locale from '../../Translate/Locale';

import WarningIcon from '../../../icons/error2.svg';

import './LoadingError.scss';

function LoadingError() {
  return (
    <div styleName="container" aria-label="loading-error">
      <div styleName="content">
        <WarningIcon styleName="icon" />
        <Text styleName="message">
          <Locale>Something went wrong</Locale>
        </Text>
      </div>
    </div>
  );
}

export default LoadingError;
