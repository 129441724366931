import Papa from 'papaparse';
import { getLanguages } from '../../../utils/langHelper';

const config = {
  quoteChar: '"',
  escapeChar: '"',
};

export function parseCSV(file) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      ...config,
      header: true,
      skipEmptyLines: 'greedy',
      complete: (results) => {
        if (results.data.length === 0) {
          return reject('empty-data');
        }

        const isValid = results.data.every((row) => validateRow(row));

        if (isValid) {
          resolve(results.data);
        } else {
          reject('wrong-format');
        }
      },
      error: (error) => {
        reject('wrong-format');
      },
    });
  });
}

export function makeCSV(translations) {
  const keys = Object.keys(translations.en);
  const languages = getLanguages();
  const fields = ['key', ...languages];

  const makeRow = (key) => [key, ...languages.map((lang) => translations[lang]?.[key])];

  return Papa.unparse(
    {
      fields,
      data: keys.map((key) => makeRow(key)),
    },
    config
  );
}

function validateRow(data) {
  const columnNames = Object.keys(data);
  const areColumnNamesValid = columnNames.every((col) => validateColumnName(col));

  if (!data.key || !areColumnNamesValid) {
    return false;
  }

  return getLanguages().some((lang) => data[lang] !== undefined);
}

/**
 * Input:
 * [
 *   { key: 'return', en: 'Return', de: 'Retouren' },
 *   { key: 'next', en: 'Next', de: 'Weiter' }
 * ]
 *
 * Output:
 * {
 *   en: { return: 'Return', next: 'Next' },
 *   de: { return: 'Retouren', next: 'Weiter' }
 * }
 */

export function groupTranslationsByLanguage(data) {
  if (data.length === 0) {
    return {};
  }

  const languages = getLanguagesFromRow(data[0]);

  return data.reduce((acc, row) => {
    languages.forEach((lang) => {
      // eslint-disable-next-line no-param-reassign
      acc = { ...acc, [lang]: { ...acc[lang], [row.key]: row[lang] } };
    });

    return acc;
  }, {});
}

function validateColumnName(val) {
  const validColumns = ['key', ...getLanguages()];

  return validColumns.includes(val);
}

function getLanguagesFromRow(row) {
  const { key, ...rest } = row;
  return Object.keys(rest);
}
