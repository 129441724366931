import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Locale from '../../Translate/Locale';
import UploadIcon from '../../../icons/upload.svg';
import InputContainer from '../../common2/InputContainer';
import FileItem from '../../common/Uploader/FileItem';

import './CsvFileSelector.scss';

function CsvFileSelector(props) {
  const inputRef = useRef(null);
  const { onChange, onRemove, children, error, errorParams, file, t } = props;

  const [isDragging, setIsDragging] = useState(false);

  const prevent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragOver = (e) => {
    setIsDragging(true);
    prevent(e);
  };

  const onDragLeave = (e) => {
    setIsDragging(false);
    prevent(e);
  };

  const onSelectFile = (e) => {
    onChange(e.target.files[0]);
    prevent(e);
    e.target.value = null; // allows to trigger change event when same file is selected
  };

  const onDrop = (e) => {
    setIsDragging(false);
    onChange(e.dataTransfer.files[0]);
    prevent(e);
    e.target.value = null;
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <InputContainer errorWithTranslation={t(error, { ...errorParams })}>
        <button
          styleName={classNames('button', { dragging: isDragging })}
          onClick={onButtonClick}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          name="csv-uploader"
        >
          <input styleName="input" ref={inputRef} type="file" accept=".csv" onChange={onSelectFile} />
          <UploadIcon styleName="icon" />
          <Locale>{children}</Locale>
        </button>
      </InputContainer>
      {!error && file && (
        <div styleName="file">
          <FileItem fileName={file.name} onRemove={onRemove} />
        </div>
      )}
    </div>
  );
}

CsvFileSelector.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  error: PropTypes.string,
  errorParams: PropTypes.object,
  file: PropTypes.object,
  children: PropTypes.string,
};

export default React.memo(withTranslation()(CsvFileSelector));
