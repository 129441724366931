import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from '../Checkbox/Checkbox';

import './OptionsList.scss';
export function OptionsList(props) {
  const { onChange, options, selectedValues } = props;

  return (
    <div styleName="container">
      {options.map(({ label, count, value }) => {
        const selectedValue = selectedValues.indexOf(value) >= 0 ? value : '';

        return (
          <div key={label} styleName={classNames('input', { selected: !!selectedValue })}>
            <Checkbox
              key={value}
              name={value}
              value={selectedValue}
              onChange={onChange}
              options={{ i18nOptions: { count } }}
            >
              {label}
            </Checkbox>
          </div>
        );
      })}
    </div>
  );
}

OptionsList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, count: PropTypes.number, value: PropTypes.string })
  ),
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default React.memo(OptionsList);
