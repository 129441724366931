import React from 'react';

import Table from '../../common2/Table';
import Text from '../../common/Text';

const columns = [
  { title: 'Carrier' },
  { title: 'Carrier country' },
  { title: 'Delivery country' },
  { title: 'SLA (days)' },
  { title: 'FHS timeout (days)' },
  { title: 'FDA timeout (days)' },
  { title: 'Delivery attempts before return' },
  { title: 'Warehouse' },
];

function SLAList(props) {
  const { sla = [] } = props;

  return (
    <Table columns={columns}>
      {sla.map(
        ({
          id,
          warehouse,
          carrier,
          deliveryCountry,
          maxFirstHubScanTimeoutDays,
          maxPromisedDeliveryDays,
          deliveryTimeoutDays,
          deliveryAttemptsBeforeReturn,
        }) => (
          <tr key={id}>
            <td>
              <Text ink>{carrier.name || '-'}</Text>
            </td>
            <td>
              <Text ink>{carrier.countryName || '-'}</Text>
            </td>
            <td>
              <Text ink>{deliveryCountry.name || '-'}</Text>
            </td>
            <td>
              <Text ink>{Number.isInteger(maxPromisedDeliveryDays) ? maxPromisedDeliveryDays : '-'}</Text>
            </td>
            <td>
              <Text ink>{Number.isInteger(maxFirstHubScanTimeoutDays) ? maxFirstHubScanTimeoutDays : '-'}</Text>
            </td>
            <td>
              <Text ink>{Number.isInteger(deliveryTimeoutDays) ? deliveryTimeoutDays : '-'}</Text>
            </td>
            <td>
              <Text ink>{Number.isInteger(deliveryAttemptsBeforeReturn) ? deliveryAttemptsBeforeReturn : '-'}</Text>
            </td>
            <td>
              <Text ink>{warehouse.name || '-'}</Text>
            </td>
          </tr>
        )
      )}
    </Table>
  );
}

export default SLAList;
