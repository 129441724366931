export const claimTypeOptions = [
  { label: 'Investigation', value: 'investigation' },
  { label: 'Partial damage', value: 'partial_damage' },
  { label: 'Whole damage', value: 'whole_damage' },
  { label: 'Missing item', value: 'missing_item' },
];

export const claimStatusOptions = [
  { label: 'Submitted ({{count}})', value: 'submitted' },
  { label: 'Resubmitted ({{count}})', value: 'resubmitted' },
  { label: 'Action required ({{count}})', value: 'action_required' },
  { label: 'In progress ({{count}})', value: 'in_progress' },
  { label: 'Accepted ({{count}})', value: 'accepted' },
  { label: 'Credit note pending ({{count}})', value: 'credit_note_pending' },
  { label: 'Successful ({{count}})', value: 'successful' },
  { label: 'Declined ({{count}})', value: 'declined' },
  { label: 'Escalating ({{count}})', value: 'escalating' },
  { label: 'Payment overdue ({{count}})', value: 'payment_overdue' },
  { label: 'Incorrect payment ({{count}})', value: 'incorrect_payment' },
  { label: 'Paid ({{count}})', value: 'paid' },
];

export const claimShipmentTypesOptions = [
  { label: 'Seven Senders', value: '7s' },
  { label: 'Non Seven Senders', value: 'non-7s' },
];

/* eslint-disable camelcase */
export const claimStatusCountMap = {
  accepted: 'acceptedCount',
  action_required: 'actionRequiredCount',
  credit_note_pending: 'creditNotePendingCount',
  declined: 'declinedCount',
  in_progress: 'inProgressCount',
  resubmitted: 'resubmittedCount',
  submitted: 'submittedCount',
  successful: 'successfulCount',
  escalating: 'escalatingCount',
  payment_overdue: 'paymentOverdueCount',
  incorrect_payment: 'incorrectPaymentCount',
  paid: 'paidCount',
};
/* eslint-enable camelcase */
