import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Locale from '../../Translate/Locale';

import './PlainButton.scss';
import { generateNameFromText } from '../../../slug';

function PlainButton(props) {
  const { onClick, icon: Icon, name, children, tabIndex, disabled, intent } = props;

  const buttonName = name || generateNameFromText(children);

  const styleName = classNames('button', intent);

  return (
    <button styleName={styleName} onClick={onClick} name={buttonName} tabIndex={tabIndex} disabled={disabled}>
      {Icon && <Icon styleName={classNames('icon', intent)} />}
      <Locale>{children}</Locale>
    </button>
  );
}

PlainButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.elementType,
  children: PropTypes.string,
  name: PropTypes.string,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  intent: PropTypes.string,
};

export default React.memo(PlainButton);
