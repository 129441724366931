import i18next from 'i18next';
import Backend from 'i18next-locize-backend';
import storage from './models/storage';

const defaultLanguage = 'en';

export function isI18nextDebugEnabled() {
  return storage.getItem('i18nextDebug') === 'true';
}

export function initI18n(store) {
  const I18NextInstance = i18next.createInstance();
  const debug = isI18nextDebugEnabled();
  const fallbackLng = debug ? false : defaultLanguage;

  store.subscribe(() => {
    const { language } = store.getState();
    if (language !== I18NextInstance.language) {
      I18NextInstance.changeLanguage(language);
    }
  });

  return I18NextInstance.use(Backend).init({
    backend: {
      projectId: 'ea2efff6-c0d2-4b8f-9478-7941ae4ac57e',
      referenceLng: defaultLanguage,
    },

    fallbackLng,
    saveMissing: false, // it just pollutes Locize with unused garbage translations from localhost(s)

    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',

    react: {
      useSuspense: false,
    },

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
  });
}
