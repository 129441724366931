import React from 'react';
import classNames from 'classnames';

import Text from '../../common/Text';
import Locale from '../../Translate/Locale';

import './CarrierClaimPaymentsResultSummary.scss';

function CarrierClaimPaymentsResultSummary(props) {
  const { title, intent, localeOptions, children } = props;

  return (
    <div styleName="container">
      <span styleName={classNames('heading', intent)}>
        <Locale>{title}</Locale>
      </span>
      <Text ink>
        <Locale options={localeOptions}>{children}</Locale>
      </Text>
    </div>
  );
}

export default React.memo(CarrierClaimPaymentsResultSummary);
