import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Row from '../../common/Row';
import FilterSelect from '../../common2/FilterSelect';
import ResetButton from './ResetButton';
import { claimTypeOptions, claimStatusOptions, claimStatusCountMap, claimShipmentTypesOptions } from './filter-options';

import './ClaimsFilters.scss';

function ClaimsFilters(props) {
  const { types = [], statuses = [], statusCounts = [], shipmentTypes = [], onApply, onClear } = props;
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedShipmentTypes, setSelectedShipmentTypes] = useState([]);

  const onApplyButtonClick = useCallback(() => {
    onApply({ statuses: selectedStatuses, types: selectedTypes, shipmentTypes: selectedShipmentTypes });
  }, [selectedStatuses, selectedTypes, selectedShipmentTypes, onApply]);

  const onClearButtonClick = useCallback(() => {
    if (statuses.length > 0 || types.length > 0 || shipmentTypes.length > 0) {
      onClear();
    }

    setSelectedTypes([]);
    setSelectedStatuses([]);
    setSelectedShipmentTypes([]);
  }, [types, statuses, shipmentTypes, setSelectedTypes, setSelectedStatuses, setSelectedShipmentTypes, onClear]);

  const onCloseStatusFilter = useCallback(() => {
    setSelectedStatuses(statuses);
  }, [statuses, setSelectedStatuses]);

  const onCloseTypeFilter = useCallback(() => {
    setSelectedTypes(types);
  }, [types, setSelectedTypes]);

  const onCloseShipmentTypeFilter = useCallback(() => {
    setSelectedShipmentTypes(shipmentTypes);
  }, [shipmentTypes, setSelectedShipmentTypes]);

  return (
    <Row spacing={8}>
      <FilterSelect
        title="Status"
        styleName="status"
        label={getLabel(selectedStatuses, claimStatusOptions)}
        options={buildStatusOptions(claimStatusOptions, statusCounts)}
        selectedValues={selectedStatuses}
        onApply={onApplyButtonClick}
        onChange={setSelectedStatuses}
        onClose={onCloseStatusFilter}
        ariaLabel="status-filter-button"
      />
      <FilterSelect
        title="Type"
        label={getLabel(selectedTypes, claimTypeOptions)}
        options={claimTypeOptions}
        selectedValues={selectedTypes}
        onApply={onApplyButtonClick}
        onChange={setSelectedTypes}
        onClose={onCloseTypeFilter}
        ariaLabel="type-filter-button"
      />
      <FilterSelect
        title="Shipment"
        label={getLabel(selectedShipmentTypes, claimShipmentTypesOptions)}
        options={claimShipmentTypesOptions}
        selectedValues={selectedShipmentTypes}
        onApply={onApplyButtonClick}
        onChange={setSelectedShipmentTypes}
        onClose={onCloseShipmentTypeFilter}
        ariaLabel="shipment-filter-button"
      />
      <ResetButton onClick={onClearButtonClick}>Clear filters</ResetButton>
    </Row>
  );
}

function buildStatusOptions(options, statusCounts) {
  return options.reduce((filteredOptions, option) => {
    const { label, value } = option;
    const count = statusCounts[claimStatusCountMap[value]];

    if (count) {
      filteredOptions.push({
        label,
        count,
        value,
      });
    }

    return filteredOptions;
  }, []);
}

function getLabel(selectedValues, options) {
  if (selectedValues.length === 1) {
    return options.find(({ value }) => value === selectedValues[0]).label;
  }

  if (selectedValues.length === 0 || selectedValues.length === options.length) {
    return 'All';
  }

  return 'Multiple';
}

ClaimsFilters.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  statuses: PropTypes.arrayOf(PropTypes.string),
  statusCounts: PropTypes.object,
  shipmentTypes: PropTypes.arrayOf(PropTypes.string),
  onSelectStatus: PropTypes.func,
  onSelectType: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func,
};

export default React.memo(ClaimsFilters);
