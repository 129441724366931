import React from 'react';
import PropTypes from 'prop-types';
import Heading4 from '../../common/Heading4';
import CarrierClaimPaymentsResultSummary from './CarrierClaimPaymentsResultSummary';
import CarrierClaimPaymentsFailedMessage from './CarrierClaimPaymentsFailedMessage';

import './CarrierClaimPaymentsResults.scss';

function CarrierClaimPaymentsResults({ filename, results }) {
  const { successful, failed } = results;

  return (
    <div styleName="container">
      <div styleName="heading">
        <Heading4 localeOptions={{ filename }}>CARRIER_CLAIM_PAYMENT_RESULTS_TITLE</Heading4>
      </div>
      <div styleName="results">
        <CarrierClaimPaymentsResultSummary
          intent="success"
          title="Successful updates"
          localeOptions={{ count: successful }}
        >
          CARRIER_CLAIM_PAYMENTS_RESULT_SUCCESSFUL
        </CarrierClaimPaymentsResultSummary>
        {failed.length > 0 && (
          <>
            <div styleName="separator" />
            <CarrierClaimPaymentsResultSummary
              intent="danger"
              title="Failed updates"
              localeOptions={{ count: failed.length }}
            >
              CARRIER_CLAIM_PAYMENTS_RESULT_FAILED
            </CarrierClaimPaymentsResultSummary>
            <div styleName="failed-messages">
              {failed.map((result, index) => (
                <CarrierClaimPaymentsFailedMessage key={index} result={result} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

CarrierClaimPaymentsResults.propTypes = {
  filename: PropTypes.string,
  results: PropTypes.object,
};

export default React.memo(CarrierClaimPaymentsResults);
