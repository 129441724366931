import React, { useCallback, useState } from 'react';

import WholeDamageStepContainer from './WholeDamageStepContainer';
import TextArea from '../../../common2/TextArea';
import ItemPicturesUploader from '../common/ItemPicturesUploader';
import useStepValidation from '../common/useStepValidation';
import { wholeDamageInfoValidator } from '../claimValidators';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import CustomerCommentInput from '../common/CustomerCommentInput';
import Column from '../../../common/layout/Column';
import InputHelpLink from '../InputHelpLink';

function WholeDamageDamageInfoStep(props) {
  const {
    onChange,
    claim,
    claim: { wholeDamageInfo },
    onNext,
  } = props;

  const [errors, onClickNext] = useStepValidation(claim, onNext, wholeDamageInfoValidator);
  const [isDisabled, setIsDisabled] = useState(false);

  const onInputChange = useCallback((value, name) => onChange({ [name]: value }), [onChange]);

  const uploaderHelpLinkElement = (
    <InputHelpLink to="https://support.portal.sevensenders.com/support/solutions/articles/15000048062-submission-document-requirements#Damage-Photo-Requirements%C2%A0">
      Damage photo requirements
    </InputHelpLink>
  );

  return (
    <WholeDamageStepContainer {...props} onNext={onClickNext} isDisabled={isDisabled}>
      <SidePanelContentHeader>Damage information</SidePanelContentHeader>
      <Column spacing="small">
        <TextArea
          label="WHOLE_DAMAGE_DESC"
          value={wholeDamageInfo}
          onChange={onInputChange}
          name="wholeDamageInfo"
          helpText="WHOLE_DAMAGE_TEXT_AREA_HELP"
          error={errors.wholeDamageInfo}
          isRequired
          noErrorTranslation
        />
        <CustomerCommentInput claim={claim} onChange={onInputChange} />
        <ItemPicturesUploader
          name="picturesOfDamage"
          label="Pictures of damage"
          helpText="CLAIMS_DAMAGE_PICTURES_HELP_TEXT"
          claim={claim}
          errors={errors}
          onChange={onInputChange}
          onBusy={setIsDisabled}
          uploaderHelpLinkElement={uploaderHelpLinkElement}
        />
      </Column>
    </WholeDamageStepContainer>
  );
}

export default React.memo(WholeDamageDamageInfoStep);
