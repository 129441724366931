import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import PageContainer from '../pagecontainer/PageContainer';
import Heading1 from '../common/Heading1/Heading1';
import Heading3 from '../common/Heading3/Heading3';
import FeatureCard from './FeatureCard/FeatureCard';
import EarlyAccessBadge from '../common/badges/EarlyAccessBadge';
import ComingSoonBadge from '../common/badges/ComingSoonBadge';
import HelpCard from './HelpCard';
import getCurrentDate from '../../utils/getCurrentDate';
import { getFirewallForSelectedShop, getSelectedShop } from '../../reducers/selectors';
import ChatbotIcon from '../../icons/homepage/ai-chatbot.svg';
import TrackingIcon from '../../icons/homepage/tracking.svg';
import ManagementIcon from '../../icons/homepage/management.svg';
import AnalyticsIcon from '../../icons/homepage/analytics.svg';
import NotificationsIcon from '../../icons/homepage/notifications.svg';
import ParcelCenterIcon from '../../icons/homepage/parcel-center.svg';
import CommCenterIcon from '../../icons/homepage/communication-center.svg';
import ReturnCenterIcon from '../../icons/homepage/returns.svg';
import LabelsIcon from '../../icons/homepage/labels.svg';
import DeliveryIcon from '../../icons/homepage/delivery.svg';
import NewReleaseBanner from './NewReleaseBanner';
import CenterLayout from '../common2/CenterLayout';
import { hasCommsCenterAccess } from '../../utils/permissionsHelper';
import useSelectedShop from '../../hooks/useSelectedShop';
import useFirewall from '../../hooks/useFirewall';
import { CHATBOT_PERMISSION } from '../../firewall/permissions';

import './HomePage.scss';
import { useTranslation } from 'react-i18next';

const trackingPagesLink =
  'https://support.portal.sevensenders.com/support/solutions/articles/15000010426-designing-and-publishing-a-tracking-page';
const customerNotificationsLink =
  'https://support.portal.sevensenders.com/support/solutions/articles/15000019891-creating-customer-notifications';
const analyticsDashboardLink =
  'https://support.portal.sevensenders.com/support/solutions/articles/15000010423-viewing-dashboards-in-analytics';

export function HomePage(props) {
  const shop = useSelectedShop();
  const firewall = useFirewall(shop.id);
  const { i18n } = useTranslation();
  const isLanguageGerman = i18n.language === 'de';
  const isComCenterEnabled = hasCommsCenterAccess(firewall);
  const hasChatbotAccess = firewall.hasAccess(CHATBOT_PERMISSION);
  const claimsManagementLearnMoreLink = isLanguageGerman
    ? 'https://support.portal.sevensenders.com/support/solutions/articles/15000058349-demn%C3%A4chst-verf%C3%BCgbar-claims-management-f%C3%BCr-nicht-seven-senders-sendungen'
    : 'https://support.portal.sevensenders.com/support/solutions/articles/15000058337-coming-soon-claims-management-for-non-seven-senders-shipments';

  const menuItems = useMemo(() => {
    if (hasChatbotAccess) {
      return [
        {
          id: '/chatbot',
          link: '/chatbot',
          title: 'ParcelAI',
          badge: <EarlyAccessBadge />,
        },
      ];
    }

    return [];
  }, [hasChatbotAccess]);

  return (
    <PageContainer {...props} menuItems={menuItems} mobileMenuOnly>
      <CenterLayout>
        <Heading1 styleName="greeting">{getGreeting()}</Heading1>
        <NewReleaseBanner isChatbotBanner={hasChatbotAccess} />
        <div styleName="title">
          <Heading3>Explore</Heading3>
        </div>
        <div styleName="features">
          {hasChatbotAccess && (
            <FeatureCard
              id="chatbot"
              icon={<ChatbotIcon />}
              title="ParcelAI"
              description="DESCRIPTION_CHATBOT"
              url="/chatbot/start-guide"
              callToActionText="GET_STARTED_CHATBOT"
              badge={<EarlyAccessBadge />}
            />
          )}
          <FeatureCard
            id="management"
            icon={<ManagementIcon />}
            title="Claims Management"
            description="DESCRIPTION_MANAGEMENT_NON7S"
            url={claimsManagementLearnMoreLink}
            callToActionText="LEARN_MORE_MANAGEMENT_NON7S"
            badge={<ComingSoonBadge />}
          />
          <FeatureCard
            id="tracking"
            icon={<TrackingIcon />}
            title="Tracking"
            description="DESCRIPTION_TRACKING"
            url="/tracking/start-guide"
            callToActionText="GET_STARTED_TRACKING"
          />
          <FeatureCard
            id="notifications"
            icon={<NotificationsIcon />}
            title="Notifications"
            description="DESCRIPTION_NOTIFICATIONS"
            url="/customer-notifications/start-guide"
            callToActionText="GET_STARTED_NOTIFICATIONS"
          />
          <FeatureCard
            id="parcel-center"
            icon={<ParcelCenterIcon />}
            title="Parcel center hompage"
            description="DESCRIPTION_PARCEL_CENTER"
            url="/parcel-finder/start-guide"
            callToActionText="DISCOVER_NOW_PARCEL_CENTER"
          />
          <FeatureCard
            id="analytics"
            icon={<AnalyticsIcon />}
            title="Analytics"
            description="DESCRIPTION_ANALYTICS"
            url="/analytics/start-guide"
            callToActionText="GET_STARTED_ANALYTICS"
          />
          <FeatureCard
            id="return-center"
            icon={<ReturnCenterIcon />}
            title="Return center"
            description="DESCRIPTION_RETURN_CENTER"
            url="/returns/start-guide"
            callToActionText="GET_STARTED_RETURNS"
          />
          <FeatureCard
            id="communications-center"
            icon={<CommCenterIcon />}
            title="Communications center"
            description="DESCRIPTION_COMMS_CENTER"
            url={isComCenterEnabled ? '/communication-center' : null}
            callToActionText="DISCOVER_NOW_COMMS_CENTER"
          />
          <FeatureCard
            id="delivery"
            icon={<DeliveryIcon />}
            title="Delivery"
            description="DESCRIPTION_DELIVERY"
            url="mailto:kam@sevensenders.com"
            callToActionText="MAKE_REQUEST_DELIVERY"
          />
          <FeatureCard
            id="labels"
            icon={<LabelsIcon />}
            title="Labels"
            description="DESCRIPTION_LABELS"
            url="mailto:kam@sevensenders.com"
            callToActionText="MAKE_REQUEST_LABELS"
          />
        </div>
        <div styleName="help-section">
          <div styleName="title">
            <Heading3>Helpful links</Heading3>
          </div>
          <div styleName="cards">
            <HelpCard text="HOMEPAGE_TIP_TRP" linkText="LINK_FOR_TRP" link={trackingPagesLink} />
            <HelpCard text="HOMEPAGE_TIP_CN" linkText="LINK_FOR_CN" link={customerNotificationsLink} />
            <HelpCard text="HOMEPAGE_TIP_ANALYTICS" linkText="LINK_FOR_AN" link={analyticsDashboardLink} />
          </div>
        </div>
      </CenterLayout>
    </PageContainer>
  );
}

function getGreeting() {
  const currentHour = getCurrentDate().getHours();

  if (currentHour >= 6 && currentHour < 12) {
    return 'Good morning';
  }

  if (currentHour >= 12 && currentHour < 18) {
    return 'Good afternoon';
  }

  if (currentHour >= 18 && currentHour < 20) {
    return 'Good evening';
  }

  return 'Good night';
}

function mapStateToProps(state) {
  return {
    selectedShop: getSelectedShop(state),
    firewall: getFirewallForSelectedShop(state),
  };
}

export default connect(mapStateToProps)(React.memo(HomePage));
