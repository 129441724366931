import React, { useCallback } from 'react';
import Locale from '../../Translate/Locale';
import CheckIcon from '../../../icons/checkbox-check.svg';

import './Checkbox.scss';

function Checkbox(props) {
  const { name, value, options, onChange = () => {}, children } = props;
  const onChangeHandler = useCallback((e) => onChange(e.target.checked, e.target.name, e), [onChange]);
  const { i18nOptions } = options || {};

  return (
    <label styleName="container">
      <input name={name} type="checkbox" styleName="checkbox" checked={value} onChange={onChangeHandler} />
      <div styleName="indicator">
        <CheckIcon />
      </div>
      <Locale options={i18nOptions}>{children}</Locale>
    </label>
  );
}

export default React.memo(Checkbox);
