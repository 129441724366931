import React from 'react';

import Table from '../../common2/Table';
import Text from '../../common/Text';

const columns = [{ title: 'Name' }, { title: 'Address' }];

function WarehousesList(props) {
  const { warehouses = [] } = props;

  return (
    <Table columns={columns}>
      {warehouses.map(({ id, name, countryName, city, zip, address }) => (
        <tr key={id}>
          <td>
            <Text ink>{name}</Text>
          </td>
          <td>
            <Text ink>{[countryName, city, zip, address].filter((str) => !!str).join(', ')}</Text>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default WarehousesList;
