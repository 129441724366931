import { useMutation } from '@tanstack/react-query';

import useNotifications from '../../../hooks/useNotifications';
import container from '../../../container';

export function useSubmitCarrierClaimPayments() {
  const { showNotice, showError } = useNotifications();

  const { mutate, isLoading, data } = useMutation({
    mutationFn: (carrierClaimPayments) => container.touchpointsApi.submitCarrierClaimPayments(carrierClaimPayments),
    onError: () => {
      showError();
    },
    onSuccess: () => {
      showNotice({
        message: 'Carrier claim payments successfully submitted',
        type: 'success',
      });
    },
  });

  return [mutate, { isLoading, results: data }];
}
