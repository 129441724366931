import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../../Translate/Locale';

/* eslint-disable camelcase */
const errorMapping = {
  multiple_claims_found: 'CARRIER_CLAIM_PAYMENTS_FAILED_MULTIPLE_CLAIMS_FOUND',
  no_claim_found: 'CARRIER_CLAIM_PAYMENTS_FAILED_NO_CLAIM_FOUND',
  negative_payment_amount: 'CARRIER_CLAIM_PAYMENTS_FAILED_NEGATIVE_PAYMENT_AMOUNT',
  invalid_currency: 'CARRIER_CLAIM_PAYMENTS_FAILED_INVALID_CURRENCY',
  invalid_status: 'CARRIER_CLAIM_PAYMENTS_FAILED_INVALID_STATUS',
  unknown: 'CARRIER_CLAIM_PAYMENTS_FAILED_UNKNOWN',
};
/* eslint-enable camelcase */

function CarrierClaimPaymentsFailedMessage({ result }) {
  const { trackingCode, errorCode, details } = result;
  const errorMessage = errorMapping[errorCode] || errorMapping.unknown;

  return (
    <div>
      <span>{trackingCode} - </span>
      <Locale options={details}>{errorMessage}</Locale>
    </div>
  );
}

CarrierClaimPaymentsFailedMessage.propTypes = {
  trackingCode: PropTypes.string,
  errorCode: PropTypes.string,
  details: PropTypes.object,
};

export default React.memo(CarrierClaimPaymentsFailedMessage);
