import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FilterSelectButton from './FilterSelectButton';
import useOutsideClick from '../../../hooks/useOutsideClick';
import FilterMenu from './FilterMenu';

import './FilterSelect.scss';
function FilterSelect(props) {
  const {
    title,
    label,
    ariaLabel,
    onChange,
    onApply = () => {},
    onClose = () => {},
    options,
    selectedValues,
    isApplyDisabled,
    className,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const onCloseHandler = () => {
    onClose();
    setIsOpen(false);
  };

  const onButtonClick = (e) => {
    setIsOpen((prevValue) => !prevValue);
    e.stopPropagation();
  };

  const onApplyHandler = () => {
    onApply();
    setIsOpen(false);
  };

  const onChangeHandler = (_, value) => {
    if (selectedValues.indexOf(value) >= 0) {
      const updatedValues = selectedValues.filter((prevValue) => prevValue !== value);
      onChange(updatedValues);
    } else {
      onChange([...selectedValues, value]);
    }
  };

  const ref = useOutsideClick(onCloseHandler);

  return (
    <div ref={isOpen ? ref : null} styleName="container">
      <FilterSelectButton isActive={isOpen} label={label} ariaLabel={ariaLabel} onClick={onButtonClick}>
        {title}
      </FilterSelectButton>
      {isOpen && (
        <FilterMenu
          options={options}
          selectedValues={selectedValues}
          className={className}
          isApplyDisabled={isApplyDisabled}
          onChange={onChangeHandler}
          onApply={onApplyHandler}
        />
      )}
    </div>
  );
}

FilterSelect.propTypes = {
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  isApplyDisabled: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onApply: PropTypes.func,
  className: PropTypes.string,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, count: PropTypes.number, value: PropTypes.string })
  ),
};

export default React.memo(FilterSelect);
