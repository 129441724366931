import React from 'react';
import { useQuery } from '@tanstack/react-query';

import SettingsPage from '../../SettingsPage';
import IntercomMessengerButton from '../../common/IntercomMessengerButton';
import { useSelectedShopId } from '../../../hooks/useSelectedShop';
import container from '../../../container';
import Loader from '../../common/Loader';
import SLAList from './SLAList';
import LoadingError from '../../common/LoadingError';

function SLAPage(props) {
  const shopId = useSelectedShopId();

  const { isLoading, isError, data } = useQuery({
    queryKey: ['sla', shopId],
    queryFn: () => container.touchpointsApi.getSLAs(shopId),
  });

  return (
    <SettingsPage
      {...props}
      title="Service Level Agreements"
      renderHeaderElements={() => <IntercomMessengerButton>Add or edit</IntercomMessengerButton>}
    >
      {isLoading && <Loader />}
      {isError && <LoadingError />}
      {data && <SLAList sla={data} />}
    </SettingsPage>
  );
}

export default SLAPage;
