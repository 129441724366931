import { useSelectedShopId } from '../../hooks/useSelectedShop';
import { useQuery } from '@tanstack/react-query';
import container from '../../container';

/**
 * @typedef ClaimsShopSettings
 * @property {number} id
 * @property {boolean} hasNon7sCarriers
 */

/**
 * @return {import('@tanstack/react-query').ClaimsShopSettings<ShopClaimsSettings>}
 */
export function useClaimsShopSettings() {
  const shopId = useSelectedShopId();

  return useQuery({
    queryKey: ['claimsShopSettings', shopId],
    queryFn: () => container.touchpointsApi.getClaimsShopSettings(shopId),
    refetchOnMount: false,
  });
}
