import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Linkify from 'linkify-react';

import Locale from '../../Translate/Locale';
import ExternalLink from '../../common2/links/ExternalLink';

function getPortalMessageFromCustomerResponse(customerResponse, language) {
  const { translations = {} } = customerResponse;
  const translation = translations[language] || translations.en;
  return translation?.portalMessage ?? null;
}

function getClaimComment(comment, i18n) {
  const actionRequiredComment = `ACTION_REQUIRED: ${comment}`;
  return i18n.exists(actionRequiredComment) ? actionRequiredComment : 'ACTION_REQUIRED: check email';
}

export const renderLink = ({ attributes, content }) => {
  const { href, ...props } = attributes;
  return (
    <ExternalLink to={href} {...props}>
      {content}
    </ExternalLink>
  );
};

function ActionRequiredMessage(props) {
  const { customerResponse, comment, i18n } = props;
  const portalMessage = customerResponse && getPortalMessageFromCustomerResponse(customerResponse, i18n.language);
  const claimComment = getClaimComment(comment, i18n);

  if (portalMessage) {
    return <Linkify options={{ render: renderLink }}> {portalMessage}</Linkify>;
  }

  return <Locale>{claimComment}</Locale>;
}

ActionRequiredMessage.propTypes = {
  customerResponse: PropTypes.object,
  comment: PropTypes.string,
};

export default React.memo(withTranslation()(ActionRequiredMessage));
