import React from 'react';
import PageContainer from '../pagecontainer/PageContainer';
import { useClaimsShopSettings } from '../claims-center/useClaimsShopSettings';
import PageLoader from '../PageLoader';

export const menuItems = [
  {
    id: '/parcel-finder',
    link: '/parcel-finder',
    title: 'Parcel finder',
  },
  {
    id: '/parcel-finder/claims-center',
    link: '/parcel-finder/claims-center',
    matchPaths: ['/parcel-finder/claims-center*'],
    title: 'Claims center',
    shopAccess: 'isClaimsEnabled',
  },
  {
    id: '/parcel-finder/carrier-claim-payments',
    link: '/parcel-finder/carrier-claim-payments',
    title: 'Carrier claim payments',
    shopAccess: 'isClaimsEnabled',
  },
  {
    id: '/analytics/claims/claims-overview',
    link: '/analytics/claims/claims-overview',
    title: 'Claims overview',
    shopAccess: 'isClaimsEnabled',
  },
  {
    id: '/parcel-finder/start-guide',
    link: '/parcel-finder/start-guide',
    title: 'Start guide',
  },
];

/**
 * @param {ClaimsShopSettings} [settings]
 */
function getMenuItems(settings) {
  return settings?.hasNon7sCarriers
    ? menuItems
    : menuItems.filter((item) => item.id !== '/parcel-finder/carrier-claim-payments');
}

function ParcelFinderContainer(props) {
  const { isLoading, data: settings } = useClaimsShopSettings();

  if (isLoading) {
    return <PageLoader {...props} />;
  }

  return <PageContainer {...props} menuTitle="Parcel Finder + Claims" menuItems={getMenuItems(settings)} collapsible />;
}

export default ParcelFinderContainer;
