import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import container from '../container';
import { sendSearchResultsToGA } from '../components/parcel-finder/gaHelper';
import { SearchTypeEnum } from '../components/parcel-finder/SearchInput/searchTypes';

const initialState = {
  isLoading: false,
  loaded: false,
  results: null,
  searchQuery: '',
  searchType: SearchTypeEnum.TRACKING_ORDERID,
};

export const parcelFinderSearch = createAsyncThunk(
  'parcelFinder/search',
  (_, { rejectWithValue, getState }) => {
    const { searchQuery, searchType } = getState().parcelFinder;
    return container.touchpointsApi
      .parcelFinderSearch(searchQuery, searchType)
      .then((results) => {
        sendSearchResultsToGA(searchQuery, results);
        return results;
      })
      .catch(rejectWithValue);
  },
  {
    condition: (_, { getState }) => !!getState().parcelFinder.searchQuery,
  }
);

export const parcelFinderReload = createAsyncThunk('parcelFinder/reload', (_, { rejectWithValue, getState }) => {
  const { searchQuery, searchType } = getState().parcelFinder;
  return container.touchpointsApi.parcelFinderSearch(searchQuery, searchType).catch(rejectWithValue);
});

const parcelFinderSlice = createSlice({
  name: 'parcelFinder',
  initialState,
  reducers: {
    setSearchQuery(state, { payload: searchQuery }) {
      state.searchQuery = searchQuery;
    },
    setSearchType(_, { payload: searchType }) {
      return { ...initialState, searchType };
    },
    clearSearch(state) {
      state.searchQuery = '';
    },
  },
  extraReducers: {
    [parcelFinderSearch.pending](state) {
      state.isLoading = true;
      state.loaded = false;
    },
    [parcelFinderSearch.fulfilled](state, action) {
      state.results = action.payload;
      state.loaded = true;
      state.isLoading = false;
    },
    [parcelFinderSearch.rejected](state) {
      state.loaded = true;
      state.results = null;
      state.isLoading = false;
    },
    [parcelFinderReload.reload](state) {
      state.isLoading = true;
      state.loaded = true;
    },
    [parcelFinderReload.fulfilled](state, action) {
      state.results = action.payload;
      state.isLoading = false;
    },
    [parcelFinderReload.rejected](state) {
      state.results = null;
      state.isLoading = false;
    },
  },
});

export const {
  searchRequest,
  searchSuccess,
  searchFailure,
  setSearchQuery,
  setSearchType,
  clearSearch,
} = parcelFinderSlice.actions;

export default parcelFinderSlice.reducer;
