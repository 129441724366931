import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import SearchInput from './SearchInput';
import { initClaim, cancelClaim, getClaimingShipmentId } from '../../reducers/claimReducer';
import Heading1 from '../common/Heading1';
import ClaimsSidePanel from './claims/ClaimsSidePanel';
import ReturnsSidePanel from './returns/ReturnsSidePanel';
import ParcelFinderSwitch from './ParcelFinderSwitch';
import { parcelFinderSearch, setSearchQuery, setSearchType, clearSearch } from '../../reducers/parcelFinderReducer';
import { getReturningOrderId } from '../../selectors/parcelFinderReturnsSelectors';
import { cancelReturn, initReturn } from '../../reducers/parcelFinderReturnsReducer';
import Locale from '../Translate/Locale';
import './ParcelFinder.scss';
import useSearchParam from './useSearchParam';

function ParcelFinder(props) {
  const {
    onSearch,
    setSearchQuery,
    setSearchType,
    searchType,
    clearSearch,
    isLoading,
    loaded,
    results,
    claimShipment,
    createReturn,
    claimingShipmentId,
    returningOrderId,
    cancelClaim,
    cancelReturn,
    history,
  } = props;

  const [query, setQuery] = useSearchParam(history, 'query');

  useEffect(() => {
    setSearchQuery(query);
    onSearch();
  }, []);

  const onSetSearchQuery = (searchQuery) => {
    setQuery(searchQuery);
    setSearchQuery(searchQuery);
  };

  const onClearSearch = () => {
    setQuery('');
    clearSearch();
  };

  const onSetSearchType = (searchType) => {
    setQuery('');
    setSearchType(searchType);
  };

  return (
    <>
      <div styleName="container">
        <div styleName="search-header">
          <Heading1 styleName="title">Find a parcel by:</Heading1>
          <div styleName="input">
            <SearchInput
              searchType={searchType}
              value={query}
              onChangeType={onSetSearchType}
              onChange={onSetSearchQuery}
              onClear={onClearSearch}
              onSearch={onSearch}
            />
          </div>
          <div styleName="search-limit-notice">
            <Locale>PARCEL_FINDER_SEARCH_LIMIT_NOTICE</Locale>
          </div>
        </div>
        <div styleName="search-result">
          <ParcelFinderSwitch
            isLoading={isLoading}
            loaded={loaded}
            results={results}
            onClaimShipment={claimShipment}
            onCreateReturn={createReturn}
          />
        </div>
      </div>
      <ClaimsSidePanel isOpen={!!claimingShipmentId} onClose={cancelClaim} />
      <ReturnsSidePanel isOpen={!!returningOrderId} onClose={cancelReturn} />
    </>
  );
}

ParcelFinder.propTypes = {
  onSearch: PropTypes.func,
  claimShipment: PropTypes.func,
  cancelClaim: PropTypes.func,
};

ParcelFinder.defaultProps = {
  onSearch: () => {},
  resetSearch: () => {},
};

function mapStateToProps(state) {
  const { isLoading, loaded, results, searchType } = state.parcelFinder;

  return {
    claimingShipmentId: getClaimingShipmentId(state),
    returningOrderId: getReturningOrderId(state),
    isLoading,
    loaded,
    results,
    searchType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSearch: () => dispatch(parcelFinderSearch()),
    setSearchQuery: (searchQuery) => dispatch(setSearchQuery(searchQuery)),
    setSearchType: (searchType) => dispatch(setSearchType(searchType)),
    clearSearch: () => dispatch(clearSearch()),
    claimShipment: (shipment) => dispatch(initClaim(shipment)),
    cancelClaim: () => dispatch(cancelClaim()),
    createReturn: (order) => dispatch(initReturn(order)),
    cancelReturn: () => dispatch(cancelReturn()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ParcelFinder));
