import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useUnleashContext } from '@unleash/proxy-client-react';

import { connectedLoadWrapper } from '../wrappers/loadWrapper';
import { fetchUserInfo } from '../actions/user';
import { refreshTokenActionCreator } from '../actions/authActions';
import { updateIntercom } from '../utils/intercomHelper';

import loadPermissions from '../wrappers/loadPermissions';
import container from '../container';

export function AuthRoot(props) {
  const { refreshTokenAction, refreshToken, token, info, isUserLoaded, selectedShopId, shops, children } = props;
  const updateContext = useUnleashContext();

  useEffect(() => {
    updateContext({ userId: selectedShopId });
  }, [updateContext, selectedShopId]);

  useEffect(() => {
    let interval;
    if (refreshToken) {
      interval = setInterval(() => refreshTokenAction(refreshToken), container.env.refreshTokenInterval * 60000);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!isUserLoaded) {
      return;
    }
    const { name, id } = info;
    const company = {
      company_id: selectedShopId,
      name: shops[selectedShopId]?.name,
    };
    updateIntercom({ user_id: id, name, company });
  }, [isUserLoaded, selectedShopId, info, shops]);

  return children;
}

AuthRoot.propTypes = {
  token: PropTypes.string,
  refreshToken: PropTypes.string,
  refreshTokenAction: PropTypes.func,
  isUserLoaded: PropTypes.bool,
  error: PropTypes.string,
  selectedShopId: PropTypes.number,
  info: PropTypes.object,
  shops: PropTypes.object,
};

const loadUserInfo = connectedLoadWrapper({
  isLoadedSelector: (state) => state.user.loaded,
  isLoadingSelector: (state) => state.user.loading,
  errorSelector: (state) => state.user.error,
  fetchAction: fetchUserInfo,
});

function mapStateToProps(state) {
  const {
    user: {
      error,
      loaded: isUserLoaded,
      info,
      selectedShopId,
      entities: { shops },
    },
    auth: { refreshToken, token },
  } = state;

  return {
    isUserLoaded,
    error,
    selectedShopId,
    info,
    shops,
    refreshToken,
    token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    refreshTokenAction: (refreshToken) => dispatch(refreshTokenActionCreator(refreshToken)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(loadUserInfo(loadPermissions(AuthRoot)));
