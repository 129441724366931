import React from 'react';

import Heading2 from '../../common/Heading2';

import './StickyPageHeader.scss';

function StickyPageHeader(props) {
  const { title, children } = props;

  return (
    <header styleName="header">
      <Heading2 styleName="heading">{title}</Heading2>
      {children}
    </header>
  );
}

export default React.memo(StickyPageHeader);
