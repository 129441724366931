import Papa from 'papaparse';
import * as yup from 'yup';
import { isValid, parse } from 'date-fns';

const config = {
  quoteChar: '"',
  escapeChar: '"',
  header: true,
  skipEmptyLines: 'greedy',
};

const headerMapping = {
  'Tracking code': 'trackingCode',
  'Payment amount': 'paymentAmount',
  'Payment currency': 'paymentCurrency',
  'Payment date': 'paymentDate',
  'Credit note number': 'creditNoteNumber',
};

const papaErrorCodeMapping = {
  MissingQuotes: 'Missing quotes',
  UndetectableDelimiter: 'Undetectable delimiter',
  TooFewFields: 'Too few fields',
  TooManyFields: 'Too many fields',
};

export function parseCSV(file) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      ...config,
      transformHeader: (header) => headerMapping[header],
      complete: (results) => {
        const parsingError = getParsingError(results);
        if (parsingError) {
          return reject(parsingError);
        }

        try {
          const trackingCodesList = getTrackingCodesList(results.data);
          const validatedData = carrierClaimPaymentsSchema.validateSync(results.data, {
            context: { trackingCodesList },
          });
          resolve(transformPaymentDates(validatedData));
        } catch (validationError) {
          const errorMessage = validationError.errors[0];
          reject(errorMessage);
        }
      },
      error: (_error) => {
        reject('Something went wrong');
      },
    });
  });
}

const carrierClaimPaymentsSchema = yup.array().of(
  yup.object().shape({
    trackingCode: yup
      .string()
      .test('is-not-duplicate', 'Duplicate tracking codes', (value, context) => {
        const { trackingCodesList } = context.options.context;
        return trackingCodesList.filter((code) => code === value).length === 1;
      })
      .required('Tracking code is required'),
    paymentAmount: yup
      .number()
      .typeError('Payment amount must be number')
      .transform((value) => Math.round(value * 100))
      .required('Payment amount is required'),
    paymentCurrency: yup.string().required('Payment currency is required'),
    paymentDate: yup
      .string()
      .test('is-valid-date', 'Payment date must be in the format dd.mm.yyyy', (value) => {
        const date = parse(value, 'dd.MM.yyyy', new Date());
        return isValid(date);
      })
      .required('Payment date is required'),
    creditNoteNumber: yup.string().label('Credit note number'),
  })
);

function getTrackingCodesList(data) {
  return data.map((row) => row.trackingCode);
}

function transformPaymentDates(data) {
  return data.map((row) => ({
    ...row,
    paymentDate: parse(row.paymentDate, 'dd.MM.yyyy', new Date()),
  }));
}

function getParsingError(results) {
  if (results.data.length === 0) {
    return 'The file is empty';
  }

  const { errors } = results;
  if (errors.length === 0) {
    return null;
  }

  const { row, code } = errors[0];

  return { row: row + 1, message: papaErrorCodeMapping[code] };
}
