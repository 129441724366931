import React, { useState } from 'react';

import Heading4 from '../../common/Heading4';
import Column from '../../common/layout/Column';
import StickyPageHeader from '../../common2/StickyPageHeader/StickyPageHeader';
import Button from '../../common2/Button/Button';
import CenterLayout from '../../common2/CenterLayout/CenterLayout';
import RichLocale from '../../common2/RichLocale';
import CsvFileSelector from '../../common2/CsvFileSelector';
import ParcelFinderContainer from '../../parcel-finder/ParcelFinderContainer';
import Check from '../../../icons/check-circle-filled.svg';
import { parseCSV } from '../../../utils/parseCarrierClaimPaymentsCSV';
import { useSubmitCarrierClaimPayments } from './useSubmitCarrierClaimPayments';
import CarrierClaimPaymentsResults from './CarrierClaimPaymentsResults';

import './CarrierClaimPaymentsPage.scss';

function CarrierClaimPaymentsPage(props) {
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [errorParams, setErrorParams] = useState({});
  const [submitCarrierClaimPayments, { isLoading, results }] = useSubmitCarrierClaimPayments();

  const parseError = (error) => {
    if (Object.keys(error).includes('message')) {
      const { row, message } = error;
      return {
        message: `Error on line {{row}}: ${message}`,
        params: { row },
      };
    }

    return { message: error };
  };

  const onFileChange = (file) => {
    setData(null);
    setFile(file);
    setError('');
    setErrorParams({});
    parseCSV(file)
      .then((data) => setData(data))
      .catch((error) => {
        const { message, params } = parseError(error);
        setError(message);
        setErrorParams(params);
      });
  };

  const deleteCsv = () => {
    setFile(null);
  };

  const submitFile = () => {
    submitCarrierClaimPayments(data);
  };

  return (
    <ParcelFinderContainer {...props}>
      <CenterLayout>
        <StickyPageHeader title="Carrier claim payments">
          <Button intent="success" icon={Check} disabled={isLoading || !data} onClick={submitFile}>
            Upload payments
          </Button>
        </StickyPageHeader>
        <div styleName="container">
          <Column>
            <div>
              <Heading4>How to upload carrier claim payments</Heading4>
              <RichLocale>CARRIER_CLAIM_PAYMENTS_PAGE_DESCRIPTION</RichLocale>
            </div>
            <CsvFileSelector
              onChange={onFileChange}
              error={error}
              errorParams={errorParams}
              file={file}
              onRemove={deleteCsv}
            >
              Upload CSV file
            </CsvFileSelector>
          </Column>
        </div>
        {results && <CarrierClaimPaymentsResults filename={file.name} results={results} />}
      </CenterLayout>
    </ParcelFinderContainer>
  );
}

export default CarrierClaimPaymentsPage;
