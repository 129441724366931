import React from 'react';

import Locale from '../../Translate/Locale';

import './Heading4.scss';

function Heading4(props) {
  return (
    <h4 className={props.className} styleName="default">
      <Locale options={props.localeOptions}>{props.children}</Locale>
    </h4>
  );
}

export default Heading4;
